import React, { Suspense, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { LogoSection, ProfileSection, PanelDropdown, NotificationSection, LanguageSection, UpdateStore } from './index';
import { AlertDialog } from '../../../components/index';
import { fetchStore } from "../../Store/EditStore/EditStoreApi";
import './Header.scss';
import { t } from "i18next";

export default function Header(props) {

  const dispatch = useDispatch();
  const { matchUpMd, toggleLeftDrawerHandler } = props;
  const [time, setTime] = useState(Date.now());
  const [showViewModal, setShowViewModal] = useState({
    show: false,
    data: null
});

useEffect(async() => {
  // store details
  let getStore = [];
  getStore = await fetchStore({}, dispatch);
  console.log("store response ::::: => " + JSON.stringify(getStore));
  // console.log("profileUpdated:::::>>",org.profileUpdated)
     if (getStore[0].profileUpdated === false) {
      setShowViewModal((state) => {
          return {
              ...state,
              show: true
          };
      });
  }
}, []);

const closeViewModalHandler = (modalName) => {
  if (modalName === "showViewModal") {
      setShowViewModal((state => {
          return {
              ...state,
              show: false
          }
      }))
  }
}

  const closeStoreUpdateModal = () => {
    setShowViewModal((state) => ({
      ...state,
      show: false,
    }));
  };


  return (
    <div>
      <Toolbar className="header-wrapper">
        <Box sx={{ width: matchUpMd ? "228px" : "auto" }}>
          <LogoSection
            toggleLeftDrawerHandler={toggleLeftDrawerHandler}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: matchUpMd ? "calc(100% - 228px)" : "100%", alignItems: "center" }}>
          <PanelDropdown />
          <Box className="right-side-section" sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>{dayjs(time).format('MMM DD YYYY')}</Box>
            <LanguageSection />
          {/*  <Suspense fallback="loading">
              <NotificationSection />
            </Suspense>*/}
            <Suspense fallback="loading">
              <ProfileSection />
            </Suspense>
          </Box>
        </Box>
      </Toolbar>
      <Box sx={{ display: { xs: 'block', md: 'none', textAlign: "right", paddingBottom: "5px", paddingRight: "30px" } }}>{dayjs(time).format('MMM DD YYYY, H:m:ss')}</Box>

      <div className="store-management-table">
        <AlertDialog
          modalName="view-store-modal"
          title={t("Update Store")}
          //  url="/view-order"
          open={showViewModal.show}
          hideConfirmationButtons={true}
          // fullScreen={'100%'}
          maxWidth={'md'}
          sx={{
            '& .MuiDialog-paper': {
              width: '900px',
              maxWidth: '900px',
            }
          }}
          closeModalHandler={() => { closeViewModalHandler("showViewModal") }}
        >
          <UpdateStore close={closeStoreUpdateModal} />
        </AlertDialog>
      </div>
    </div>
  )
}