import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, CardMedia } from "@mui/material";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import { printOptionList, taxOptionList, taxTypeList, taxNameList } from '../../../../Utils/constants';
import { InputField, CustomSelect, CustomInputField, RadioButton } from '../../../../components/ReactHookForm/index';
import { showSnackbar } from '../../../../redux/reducer/appSlice';
import { updateStore } from '../../../Store/EditStore/EditStoreApi';
import { createTax } from '../../../Tax/EditTax/EditTaxApi';
import './UpdateStore.scss';



const UpdateStore = (props) => {
  const STORE_ID = localStorage.getItem("storeId");
 // console.log("Store idddddddddd  =>>>>>",STORE_ID);
  const dispatch = useDispatch();
  const [selectValues, setSelectValues] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [onSumbitLoader, setOnSumbitLoader] = useState(false);
  const { handleSubmit, control } = useForm();

  const questions = [
    {
      question: t("Update your Store Address"),
      fields: [
        {
          id: "address1",
          label: "Address",
          rules: { required: t("Address is required") }
        },
        {
          id: "businessLocation",
          label: "Location",
          rules: { required: t("Location is required") }
        },
        {
          id: "city",
          label: "City",
          rules: { required: t("City is required") }
        },
        {
          id: "businessPinCode",
          label: "Pincode",
          rules: { required: t("Pincode is required"), pattern: { value: /^[1-9][0-9]{5}$/, message: "Please enter a valid Pincode" } }
        },
        {
          id: "businessState",
          label: "State",
          rules: { required: t("State is required") }
        },
        {
          id: "businessCountry",
          label: "Country",
          rules: { required: t("Country is required") }
        }
      ]
    },
    {
      question: t("What print option do you need?"),
      fields: [
        {
          id: "printOption",
          label: "Print Option",
          options: printOptionList.type,
          rules: { required: t("Print Option is required") }
        }
      ]
    },
    {
      question: t("Do you want tax?"),
      fields: [
        {
          id: "tax",
          label: t("Tax"),
          options: [
            { value: "yes", label: t("Yes") },
            { value: "no", label: t("No") }
          ],
          rules: { required: t("Tax is required") }
        }
      ]
    },
    // Additional fields that should appear when 'Yes' is selected for tax
    {
      question: t("Provide Tax Details"),
      fields: [
        { id: "invIsTaxInvoice", label: t("Tax Applicable"), options: [{ value: true, label: "Yes" }, { value: false, label: "No" }], rules: { required: t("Tax Applicable is required") } },
        { id: "gstNumber", label: t("GST Number"), rules: { required: t("GST Number is required"), pattern: { value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, message: "Please enter a valid GST Number" } } }, 
        { id: "taxOption", label: t("Tax Option"), options: taxOptionList.type, rules: { required: t("Tax Option is required") } },
        { id: "taxType", label: t("Tax Type"), options: taxTypeList.type, rules: { required: t("Tax Type is required") } },
        { id: "taxRate", label: t("Tax Rate"), rules: { required: t("Tax Rate is required") } },
        { id: "taxName", label: t("Tax Name"), options: taxNameList.type, rules: { required: t("Tax Name is required") } },
        { id: "taxDescription", label: t("Tax Description"), rules: { required: t("Tax Description is required") } }
      ]
    }
  ];

  const handleCustomInputChange = (id, value) => {
    setSelectValues(prevValues => ({
      ...prevValues,
      [id]: value
    }));
  };

  const submitHandler = async (formData) => {
    formData.profileUpdated = true;
    console.log("Form Data:", { ...formData, ...selectValues });
    const dataToSubmit = { ...formData, ...selectValues };

    if (formData.tax === "no") {
    setCurrentQuestionIndex(null);
    //console.log("Select value completed =>>>>>>", dataToSubmit);
    delete dataToSubmit.tax;
    console.log("Select value Store only =>>>>>>", dataToSubmit);
    setOnSumbitLoader(true);
    let response = await updateStore({ data: dataToSubmit, STORE_ID, dispatch });
    setOnSumbitLoader(false);
    console.log("storeResponse without tax =>>>>>>", response);
    if (response && response.toLowerCase() === "storesuccess") {
      dispatch(showSnackbar({ type: "success", message: `Store Edited successfully` }));
      props.close();
    }
    } else {
      
    if (currentQuestionIndex === questions.length - 1) {
      console.log("Select value all value =>>>>>>", dataToSubmit);
      const storeFormData = {
        address1: dataToSubmit.address1,
        businessCountry: dataToSubmit.businessCountry,
        businessLocation: dataToSubmit.businessLocation,
        businessPinCode: dataToSubmit.businessPinCode,
        businessState: dataToSubmit.businessState,
        city: dataToSubmit.city,
        gstNumber: dataToSubmit.gstNumber,
        printOption: dataToSubmit.printOption,
        invIsTaxInvoice: dataToSubmit.invIsTaxInvoice,
        profileUpdated : true
      };
    
      const taxFormData = {
        taxDescription: dataToSubmit.taxDescription,
        taxName: dataToSubmit.taxName,
        taxOption: dataToSubmit.taxOption,
        taxRate: dataToSubmit.taxRate,
        taxType: dataToSubmit.taxType,
      };

      setOnSumbitLoader(true);
      let storeResponse = await updateStore({ data: storeFormData, STORE_ID, dispatch });
      let taxResponse = await createTax({ data: taxFormData, dispatch });
      setOnSumbitLoader(false);

      console.log("storeResponse all value =>>>>>>", storeResponse);
      console.log("taxResponse all value =>>>>>>", taxResponse);
      if (storeResponse && storeResponse.toLowerCase() === "storesuccess" && taxResponse && taxResponse.toLowerCase() === "taxsuccess") {
        dispatch(showSnackbar({ type: "success", message: `Store updated and Tax created successfully` }));
        props.close();
      }
    }else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    }
  };

  // Get the current question based on the current index
  const currentQuestion = questions[currentQuestionIndex];




  return (
    <div className="storeUpdate-page page">
      {/* Check if currentQuestion is defined before rendering */}
      {currentQuestion && (
        <>
          <p>{currentQuestion.question}</p>
          <div className="form-card card-wrapper-default">
            <form onSubmit={handleSubmit((data) => submitHandler(data))}>
              <div className="form-fields-block">
                {currentQuestion.fields.map(field => (
                  field.options ? (
                    <CustomSelect
                      key={field.id}
                      id={field.id}
                      label={t(field.label)}
                      control={control}
                      variant="standard"
                      value={selectValues[field.id] || ''}
                      handleCustomInputChange={(e) => handleCustomInputChange(field.id, e.target.value)}
                      rules={field.rules}
                      options={field.options}
                    />
                  ) : (
                    <InputField
                      key={field.id}
                      id={field.id}
                      label={t(field.label)}
                      control={control}
                      variant="standard"
                      rules={field.rules}
                    />
                  )
                ))}
              </div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  style={{ float: "right", marginRight: "0.0rem" }}
                  className="submit-button"
                >
                  {t("Save")}
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default UpdateStore;


  


